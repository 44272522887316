import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import inst_logo from '../../../../assets/images/dashboard/account/inst_logo.svg';
import email_icon from '../../../../assets/images/dashboard/account/email_icon.svg';
import privacy_icon from '../../../../assets/images/dashboard/account/privacy_icon.svg';
import AccountNotFoundModal from './components/AccountNotFoundModal';
import AccountIsPrivateModal from './components/AccountIsPrivateModal';
import {
  InstagramUserSuccessResponse,
  fetchInstagramUser,
} from '../../../../services/requests';
import SomethingWentWrong from './components/SomethingWentWrong';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import { STORAGE_USERNAME_KEY } from '../../../../utils/constants';
import AnalysingAccount from './components/AnalysingAccount';

function InstagramAccount() {
  const {
    setInstagramUserData,
    handleSendPDFReportData,
    subscriptionId,
    firebaseUserCredential,
    isHasProfileReportSubscription
  } = useAppState();
  const theme = useTheme();
  const [isAccountNotFoundModalOpen, setAccountNotFoundModalOpen] =
    useState(false);
  const [isAccountIsPrivateModalOpen, setAccountIsPrivateModalOpen] =
    useState(false);
  const [isSomethingWentWrongModalOpen, setSomethingWentWrongModalOpen] =
    useState(false);
  const [userName, setUserName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const storageUserName = window.localStorage.getItem(STORAGE_USERNAME_KEY);

  const handleGetAnalysis = () => {
    if (userName !== '') {
      fetchUserData(userName);
    }
  };

  const fetchUserData = async (processedUsername: string) => {
    setLoading(true);

    fetchInstagramUser({
      username: processedUsername,
      subscriptionId,
      uid: firebaseUserCredential?.uid ?? null,
      environment: process.env.REACT_APP_ENVIRONMENT ?? null,
    })
      .then((response) => {
        const success = response as InstagramUserSuccessResponse;
        if (success.response) {
          if (success.response.private) {
            setAccountIsPrivateModalOpen(true);
          } else {
            window.localStorage.setItem(
              STORAGE_USERNAME_KEY,
              processedUsername
            );
            setInstagramUserData(success.response);
            if (isHasProfileReportSubscription) {
              handleSendPDFReportData(processedUsername);
            }
          }
        }
      })
      .catch((error) => {
        console.error('fetchInstagramUser error', error);
        if (error.status === 404) {
          setAccountNotFoundModalOpen(true);
        } else {
          setSomethingWentWrongModalOpen(true);
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (storageUserName) {
      setLoading(true);
      fetchUserData(storageUserName);
    }
  }, [storageUserName]);

  if (isLoading) return <AnalysingAccount />;
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '430px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          minHeight: '100%',
          paddingTop: '34px',
        }}
      >
        <img width={264} height={264} src={inst_logo} alt="instagram"></img>

        <Typography
          sx={{ color: theme.palette.text.primary, margin: '32px 24px 8px' }}
          variant="sTitleS"
        >
          Instagram account
        </Typography>

        <Typography
          sx={{
            color: theme.palette.text.secondary,
            margin: '0px 24px 32px',
            textAlign: 'center',
          }}
          variant="sSubTitle"
        >
          Enter your Instagram username to get full data analysis
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            padding: '0 20px 32px',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            '& img': {
              position: 'absolute',
              top: '15px',
              left: '40px',
            },
            '& input': {
              width: '100%',
              height: '52px',
              background: 'rgb(245,245,245)',
              border: 'none',
              borderRadius: '12px',
              color: theme.palette.text.primary,
              fontFamily: 'Avenir Next',
              fontWeight: 500,
              fontSize: '17px',
              paddingLeft: '56px',
              '&:focus-visible': {
                outline: 'none',
              },
            },
            '& input::placeholder': {
              color: theme.palette.text.secondary,
              fontFamily: 'Avenir Next',
              fontWeight: 500,
              opacity: 0.5,
              fontSize: '17px',
            },
          }}
        >
          <img width={24} height={24} src={email_icon} alt="email"></img>

          <input
            autoCapitalize='none'
            autoCorrect='off'
            autoComplete='off'
            placeholder="“Sherlock” for example"
            type="text"
            value={userName}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isLoading) {
                handleGetAnalysis();
              }
            }}
            onChange={(e) => {
              setUserName((e.target.value).toLowerCase());
            }}
          ></input>
          <button
            style={{
              cursor: 'pointer',
              background: '#256BFD',
              fontSize: '17px',
              color: '#fff',
              fontWeight: 600,
              fontFamily: 'Avenir Next',
              textTransform: 'none',
              height: '56px',
              width: '100%',
              borderRadius: '12px',
              border: 'none',
              WebkitTapHighlightColor: 'transparent',
            }}
            onClick={isLoading ? undefined : handleGetAnalysis}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              ></CircularProgress>
            ) : (
              'Get my analysis'
            )}
          </button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              height: '1px',
              background: 'rgb(245,245,245)',
              width: '100%',
              marginBottom: '32px',
            }}
          ></Box>
          <img width={24} height={24} src={privacy_icon} alt="privacy"></img>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              margin: '8px 24px 32px',
              textAlign: 'center',
            }}
            variant="sSubTitle"
          >
            Your instagram account is safe. We don’t see or store your password.
            We will never take action on your behalf
          </Typography>
        </Box>
      </Box>
      <AccountNotFoundModal
        isModalOpen={isAccountNotFoundModalOpen}
        setModalOpen={setAccountNotFoundModalOpen}
      />
      <AccountIsPrivateModal
        isModalOpen={isAccountIsPrivateModalOpen}
        setModalOpen={setAccountIsPrivateModalOpen}
      />
      <SomethingWentWrong
        isModalOpen={isSomethingWentWrongModalOpen}
        setModalOpen={setSomethingWentWrongModalOpen}
      />
    </Box>
  );
}

export default InstagramAccount;
