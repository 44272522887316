import { signOut } from 'firebase/auth';
import { auth } from '../services/firebase';
import { LOGOUT_TIMEOUT } from './constants';

export const startLogoutTimer = () => {
  // Get the login timestamp
  const loginTimestamp = parseInt(window.localStorage.getItem('loginTimestamp') || '0', 10);

  // Calculate the remaining time
  const timeElapsed = Date.now() - loginTimestamp;
  const timeRemaining = Math.max(LOGOUT_TIMEOUT - timeElapsed, 0);
  // If the timeRemaining is 0 or negative, log out immediately
  if (timeRemaining <= 0) {
    logoutUser();
  } else {
    // Set a timer to log out the user after timeRemaining
    setTimeout(logoutUser, timeRemaining);
  }
};

export const logoutUser = async () => {
  await signOut(auth);
  window.localStorage.removeItem('loginTimestamp');
};
