import { LessonItem as LessonItemType } from '../../../../../services/requests';
import { Box, Typography, useTheme } from '@mui/material';
import BaseButton from '../../../components/Button';

export default function LessonItem({ item, index }: { item: LessonItemType; index: number }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        overflow: 'hidden',
        borderRadius: '24px',
        border: '1px solid rgba(35, 38, 49, 0.10)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          marginBottom: '20px',
        }}
      >
        <Box>
          <Box
            sx={{
              borderRadius: '23px',
              background: 'rgba(37, 107, 253, 0.10)',
              padding: '5px 15px',
              fontFamily: 'Avenir Next',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: 'normal',
              color: '#256BFD',
              width: 'max-content',
            }}
          >
            Lesson {index + 1}
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: 'normal',
              marginTop: '5px',
              marginBottom: '5px',
              textAlign: 'left',
              color: theme.palette.text.primary,
            }}
          >
            {item.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: 'normal',
              textAlign: 'left',
              color: theme.palette.text.secondary,
            }}
          >
            {item.subTitle}
          </Typography>
        </Box>
        <img
          style={{
            margin: '-20px -20px 0 0',
            minWidth: '107px',
            maxHeight: '189px',
          }}
          width={107}
          src={item.image}
          alt="lesson"
        ></img>
      </Box>
      <BaseButton
        withIcon
        onClick={() => {
          window.open(window.location.origin + item.lessonPdf, '_self');
        }}
      >
        View lesson {index + 1}
      </BaseButton>
    </Box>
  );
}
