import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import BaseButton from '../../components/Button';
import arrow from '../../../../assets/svg/arrow_micro.svg';
import micro_checkmark from '../../../../assets/svg/micro_checkmark.svg';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import { createSubscription } from '../../../../services/requests';
import { STORAGE_USERNAME_KEY } from '../../../../utils/constants';

function SubscriptionCard({
  img,
  title,
  purchasedTitle,
  subTitle,
  purchasedSubTitle,
  btnText,
  btnPurchasedText,
  prevPrice,
  currPrice,
  discountPercent,
  planId,
  handleDownloadClick,
  isPurchased,
  sendReportData,
}: {
  img: string;
  title: string;
  purchasedTitle?: string;
  subTitle: string;
  purchasedSubTitle?: string;
  btnText: string;
  btnPurchasedText: JSX.Element | null;
  prevPrice: number;
  currPrice: number;
  discountPercent: number;
  planId: string;
  handleDownloadClick: () => void;
  isPurchased: boolean;
  sendReportData?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { firebaseUserCredential, checkUserSubscription, handleSendPDFReportData } = useAppState();

  const handleGetClick = () => {
    if (loading) return;
    if (firebaseUserCredential && firebaseUserCredential.email) {
      setLoading(true);
      createSubscription({
        plan_id: planId,
        email: firebaseUserCredential.email,
        firebase_id: firebaseUserCredential.uid,
      })
        .then(async (response) => {
          if ('error' in response) {
            setError(response.error);
          }
          if ('id' in response) {
            await checkUserSubscription(firebaseUserCredential.uid);

            const storageUserName = window.localStorage.getItem(STORAGE_USERNAME_KEY);
            if (storageUserName && sendReportData) {
              handleSendPDFReportData(storageUserName);
            }
          }
        })
        .catch(async (error) => {
          let parsedErrorMessage = '';
          try {
            const errorJson = await error.json();
            if ('error' in errorJson) {
              parsedErrorMessage = errorJson.error;
            }
          } catch (err) {
            console.log(err);
          }
          const errorMessage = parsedErrorMessage !== '' ? parsedErrorMessage : error;
          setError(JSON.stringify(errorMessage));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Box
      key={title}
      sx={{
        position: 'relative',
        background: '#F5F2ED',
        borderRadius: '20px',
        padding: '30px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        img: {
          width: '182px',
        },
      }}
    >
      {isPurchased && (
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: '#56AC14',
            padding: '4px 8px',
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '11px',
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: 'normal',
            color: '#FFF',
            img: {
              maxWidth: '10px',
            },
          }}
        >
          <img src={micro_checkmark} alt={'purchased'}></img>
          PURCHASED
        </Box>
      )}

      <img src={img} alt={title}></img>

      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: 'normal',
          color: '#232631',
          textAlign: 'center',
          marginTop: '20px',
          userSelect: 'none',
        }}
      >
        {isPurchased ? purchasedTitle ?? title : title}
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: 'normal',
          color: '#8C8E96',
          textAlign: 'center',
          userSelect: 'none',
          marginTop: '10px',
        }}
      >
        {isPurchased ? purchasedSubTitle ?? subTitle : subTitle}
      </Typography>
      {error !== '' && (
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            paddingTop: '15px',
            span: {
              color: '#E53E3E',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: 'normal',
            },
          }}
        >
          <span>{error}</span>
        </Box>
      )}
      {isPurchased ? (
        <>
          {btnPurchasedText && (
            <BaseButton
              disabled={loading}
              style={{
                fontSize: '16px',
                lineHeight: 'normal',
                width: '100%',
                marginTop: '20px',
              }}
              onClick={handleDownloadClick}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                {btnPurchasedText}
              </Box>
            </BaseButton>
          )}
        </>
      ) : (
        <BaseButton
          disabled={loading}
          style={{
            fontSize: '16px',
            lineHeight: 'normal',
            width: '100%',
            marginTop: '20px',
            height: '60px'
          }}
          onClick={handleGetClick}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              />
            ) : (
              <>{btnText}</>
            )}
          </Box>
        </BaseButton>
      )}

      {!isPurchased && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            marginTop: '10px',
            img: {
              maxWidth: '10px',
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              fontSize: '14px',
              color: '#8C8E96',
              lineHeight: 'normal',
              userSelect: 'none',
              textDecoration: 'line-through',
              textDecorationColor: '#E53E3E',
            }}
          >
            ${prevPrice}
          </Typography>
          <img src={arrow} alt="arrow"></img>
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 600,
              fontSize: '14px',
              color: '#E53E3E',
              lineHeight: 'normal',
              userSelect: 'none',
            }}
          >
            ${currPrice}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 600,
              fontSize: '10px',
              color: '#E53E3E',
              lineHeight: 'normal',
              userSelect: 'none',
              display: 'block',
              padding: '3px 8px',
              backgroundColor: 'rgba(229, 62, 62, 0.10)',
              borderRadius: '20px',
            }}
          >
            {discountPercent}% OFF
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default SubscriptionCard;
