import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import BaseModal from '../common/BaseModal';
import { useAppState } from '../../providers/AppProvider.hooks';

function EmailSended() {
  const theme = useTheme();
  const { activationEmailSended: emailSended, setActivationEmailSended: setEmailSended } = useAppState();

  async function onClick() {
    setEmailSended(null);
  }

  return (
    <BaseModal isModalOpen={emailSended !== null} setModalOpen={() => {}} showClose={false}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.primary,
            margin: '24px 0px 8px',
            textAlign: 'center',
          }}
          variant="sTitleMS"
        >
          First, let's activate your account!
        </Typography>

        <Typography
          variant="sSubTitle"
          sx={{
            color: theme.palette.text.secondary,
            margin: '0px 0px 24px',
            textAlign: 'center',
          }}
        >
          Recovery email was sent to {emailSended}. Check your email, please.
        </Typography>
        <button
          style={{
            cursor: 'pointer',
            background: '#256BFD',
            fontSize: '15px',
            color: '#fff',
            fontWeight: 600,
            fontFamily: 'Avenir Next',
            textTransform: 'none',
            height: '56px',
            width: '100%',
            borderRadius: '12px',
            border: 'none',
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={onClick}
        >
          Ok
        </button>
      </Box>
    </BaseModal>
  );
}

export default EmailSended;
