import React, { useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

import { useAppState } from '../../providers/AppProvider.hooks';
import background from '../../assets/images/sing_in/sign_in_background_small@2x.jpg';
import { AppRoutes, EMAIL_REGEX } from '../../utils/constants';
import useScrollTop from '../../hooks/useScrollTop';
import { useNavigate } from 'react-router-dom';

function SignIn() {
  useScrollTop();
  const { handleLogIn, isSignInLoading, authenticationErrorMessage } = useAppState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const DEFAULT_ERRORS_VALUE = { email: false, password: false };
  const [errors, setErrors] = useState(DEFAULT_ERRORS_VALUE);
  const navigate = useNavigate();
  const theme = useTheme();
  const handleSignIn = () => {
    if (isSignInLoading) return;
    const loginErrors = {
      email: !EMAIL_REGEX.test(email),
      password: password.length < 6,
    };
    if (Object.values(loginErrors).some((val) => val === true)) {
      setErrors(loginErrors);
    } else {
      handleLogIn(email, password);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        top: 0,
        bottom: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          maxWidth: '430px',
          margin: 'auto',
          backgroundImage: `url(${background})`,
          backgroundSize: 'contain',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            padding: '0px 24px 34px',
            borderRadius: '24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '34px',
              lineHeight: '38px',
              marginBottom: '24px',
            }}
          >
            Analyze your Instagram account and gain valuable insights
          </Typography>
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '16px',
              '& input': {
                width: '100%',
                height: '52px',
                background: 'rgb(245,245,245)',
                border: 'none',
                borderRadius: '12px',
                marginBottom: '16px',
                color: theme.palette.text.primary,
                fontFamily: 'Avenir Next',
                fontWeight: 500,
                fontSize: '17px',
                paddingLeft: '23px',
                '&:focus-visible': {
                  outline: 'none',
                },
              },
              '& input::placeholder': {
                color: theme.palette.text.secondary,
                fontFamily: 'Avenir Next',
                fontWeight: 500,
                opacity: 0.5,
                fontSize: '17px',
              },
              '& p': {
                fontFamily: 'Avenir Next',
                fontWeight: 400,
                fontSize: '13px',
                marginBottom: '4px',
              },
              '& .textWrapper': {
                display: 'flex',
                justifyContent: 'space-between',
              },
              '& .error': {
                color: 'rgb(253, 37, 69)',
              },
            }}
          >
            <Box className="textWrapper">
              <Typography>Email address</Typography>
              {errors.email && <Typography className="error">Wrong email format</Typography>}
            </Box>
            <input
              placeholder="Enter your email address"
              type="email"
              style={{
                outline: errors.email ? '1px solid rgb(253, 37, 69)' : undefined,
              }}
              value={email}
              onChange={(e) => {
                setErrors(DEFAULT_ERRORS_VALUE);
                setEmail(e.target.value);
              }}
            ></input>
            <Box className="textWrapper">
              <Typography>Password</Typography>
              {errors.password && <Typography className="error">Min 6 symbols</Typography>}
            </Box>
            <input
              placeholder="Enter your password"
              type="password"
              style={{
                outline: errors.password ? '1px solid rgb(253, 37, 69)' : undefined,
              }}
              value={password}
              onChange={(e) => {
                setErrors(DEFAULT_ERRORS_VALUE);
                setPassword(e.target.value);
              }}
            ></input>
            <Typography
              onClick={() => {
                navigate(AppRoutes.ACCESS_RECOVERY);
              }}
              sx={{
                fontWeight: '600!important',
                color: 'rgb(37, 107, 253)',
                textAlign: 'right',
                marginTop: '-8px',
                cursor: 'pointer',
              }}
            >
              Forgot password?
            </Typography>
          </Box>

          {authenticationErrorMessage && (
            <Box className="textWrapper" sx={{ marginBottom: '16px' }}>
              <Typography className="error" sx={{ textAlign: 'center', color: 'rgb(253, 37, 69)' }}>
                {authenticationErrorMessage}
              </Typography>
            </Box>
          )}

          <button
            style={{
              cursor: 'pointer',
              background: '#256BFD',
              fontSize: '15px',
              color: '#fff',
              fontWeight: 600,
              fontFamily: 'Avenir Next',
              textTransform: 'none',
              height: '56px',
              width: '100%',
              borderRadius: '12px',
              border: 'none',
              WebkitTapHighlightColor: 'transparent',
            }}
            onClick={handleSignIn}
          >
            {isSignInLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              />
            ) : (
              <>Sign in</>
            )}
          </button>
        </Box>
      </Box>
    </Box>
  );
}

export default SignIn;
