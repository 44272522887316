import img1 from '../../../../assets/images/dashboard/pdfLessons/mask_group_1.webp';
import img2 from '../../../../assets/images/dashboard/pdfLessons/mask_group_2.webp';
import img3 from '../../../../assets/images/dashboard/pdfLessons/mask_group_3.webp';
import img4 from '../../../../assets/images/dashboard/pdfLessons/mask_group_4.webp';
import img5 from '../../../../assets/images/dashboard/pdfLessons/mask_group_5.webp';
import img6 from '../../../../assets/images/dashboard/pdfLessons/mask_group_6.webp';
import img7 from '../../../../assets/images/dashboard/pdfLessons/mask_group_7.webp';
import img8 from '../../../../assets/images/dashboard/pdfLessons/mask_group_8.webp';
import img9 from '../../../../assets/images/dashboard/pdfLessons/mask_group_9.webp';
import img10 from '../../../../assets/images/dashboard/pdfLessons/mask_group_10.webp';
import { LessonItem } from '../../../../services/requests';

export const lessons: LessonItem[] = [
  {
    title: 'Crafting the Perfect Instagram Bio',
    subTitle:
      'Teach Instagram users how to write an engaging bio that clearly represents their brand and encourages new visitors to follow.',
    image: img1,
    lessonPdf: '/pdf/Lesson1.pdf',
  },
  {
    title: 'Optimizing Your Profile Picture & Highlights',
    subTitle:
      'Help Instagram users choose the right profile picture and make the most out of Instagram highlights to visually represent their brand.',
    image: img2,
    lessonPdf: '/pdf/Lesson2.pdf',
  },
  {
    title: 'Content Planning & Posting Frequency',
    subTitle:
      'Teach Instagram users how to develop a consistent content calendar and posting schedule to keep followers engaged.',
    image: img3,
    lessonPdf: '/pdf/Lesson3.pdf',
  },
  {
    title: 'Leveraging Instagram Stories for Engagement',
    subTitle: 'Show how to use Instagram Stories to interact with followers in real-time and keep them engaged.',
    image: img4,
    lessonPdf: '/pdf/Lesson4.pdf',
  },
  {
    title: 'Writing Captions That Spark Conversation',
    subTitle: 'Help Instagram users write engaging captions that prompt followers to comment, share, and save posts.',
    image: img5,
    lessonPdf: '/pdf/Lesson5.pdf',
  },
  {
    title: 'Hashtag Strategy to Increase Visibility',
    subTitle: 'Learn how to effectively use hashtags to expand users’ reach and attract more followers.',
    image: img6,
    lessonPdf: '/pdf/Lesson6.pdf',
  },
  {
    title: 'Engaging with Your Community',
    subTitle:
      'Emphasize the importance of interacting with followers and other accounts to build community and increase engagement.',
    image: img7,
    lessonPdf: '/pdf/Lesson7.pdf',
  },
  {
    title: 'Running Giveaways & Contests to Boost Engagement',
    subTitle:
      'Teach students how to run successful giveaways and contests that attract attention and increase interaction.',
    image: img8,
    lessonPdf: '/pdf/Lesson8.pdf',
  },
  {
    title: 'Analyzing Insights to Improve Your Strategy',
    subTitle:
      'Show students how to use Instagram Insights to understand their audience and improve their engagement strategies.',
    image: img9,
    lessonPdf: '/pdf/Lesson9.pdf',
  },
  {
    title: 'Collaborating with Influencers & Brands',
    subTitle:
      'Help students understand the power of collaborations and how to work with influencers and brands to grow their audience.',
    image: img10,
    lessonPdf: '/pdf/Lesson10.pdf',
  },
];
