import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { cancelSubscriptionsWithParameter } from '../services/requests';
import { APP_CONSTANTS } from '../utils/constants';
import background from '../assets/svg/dashboard_mobile_background.svg';
import Layout from './Dashboard/components/Layout';
import Loader from './Loader';

function CancelSubscription() {
  const [texts, setTexts] = useState({ title: '', subTitle: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const content = urlParams.get('from');

    if (content) {
      cancelSubscriptionsWithParameter({
        content: content,
      })
        .then((response) => {
          setTexts({
            title: 'Your subscription has been canceled',
            subTitle: "We've successfully cancelled your subscription.",
          });
        })
        .catch((error) => {
          console.error('cancelSubscriptionsWithParameter error', error);
          setTexts({
            title: 'No subscriptions',
            subTitle:
              'Unfortunately there are no subscription plans attached to this mail. Check if the specified email address is correct',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Layout>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'repeat-x',
        }}
      >
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px',
            maxWidth: '460px',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '9px',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <img width={48} height={48} src={'/logo128.webp'} alt="logo"></img>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '15px',
                  color: '#232631',
                  lineHeight: 'normal',
                }}
              >
                MetricMaster
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,

                  fontSize: '10.5px',
                  color: '#8C8E96',
                  lineHeight: 'normal',
                }}
              >
                Profile Analyzer
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: '20px 0 40px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '24px',
                userSelect: 'none',
                textAlign: 'center',
                color: 'rgba(40, 38, 36, 1)',
                lineHeight: 'normal',
              }}
            >
              {texts.title}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                userSelect: 'none',
                textAlign: 'center',
                color: '#908C89',
                lineHeight: 'normal',
                marginTop: '10px',
                marginBottom: '20px',
              }}
            >
              {texts.subTitle}
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                width: '100%',
                margin: 'auto 0 0',
                padding: '20px 0',
                borderTop: '1px solid #23263110',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '19px',
                  textAlign: 'center',
                  color: '#282624',
                  '& a': { color: '#282624', textDecoration: 'underline' },
                }}
              >
                Have a question? Reach our friendly support team <a href={`mailto:${APP_CONSTANTS.SUPPORT}`}>here</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default CancelSubscription;
