import PageLayout from '../../components/PageLayout';
import { Box, Typography, useTheme } from '@mui/material';
import { ReactComponent as PdfLessons } from '../../../../assets/svg/pdf_lessons_icon.svg';

import { LessonItem } from './components';

import useScrollTop from '../../../../hooks/useScrollTop';
import { lessons } from './PdfCourse.helper';

export default function PdfCourse() {
  useScrollTop();

  const theme = useTheme();

  return (
    <PageLayout title="Instagram PRO course">
      <Box
        sx={{
          padding: '30px 20px 0',
        }}
      >
        <Box
          sx={{
            borderRadius: '16px',
            background: '#FFF',
            boxShadow: '0px 5px 10px 0px rgba(85, 61, 229, 0.05)',
            padding: '8px 12px',
            display: 'flex',
            gap: '5px',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'max-content',
            margin: 'auto',
            fontFamily: 'Avenir Next',
            fontSize: '10px',
            fontWeight: '700',
            color: '#232631',
            textTransform: 'uppercase',
          }}
        >
          <PdfLessons />
          10-lessons pdf course
        </Box>
        <Typography
          sx={{
            marginTop: '15px',
            fontFamily: 'Avenir Next',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: 'normal',
            textAlign: 'center',
            marginBottom: '30px',
            color: theme.palette.text.primary,
          }}
        >
          Strategies to Boost Engagement on Your Instagram Profile
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {lessons.map((item, index) => (
            <LessonItem key={item.title} item={item} index={index} />
          ))}
        </Box>
      </Box>
    </PageLayout>
  );
}
