import React, { useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

import { EMAIL_REGEX } from '../../utils/constants';
import { sendActivationByEmail } from '../../services/requests';
import PasswordChange from './PasswordChange';
import { useAppState } from '../../providers/AppProvider.hooks';

function AccessRecovery() {
  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [sendError, setSendError] = useState<string | null>(null);
  const { setActivationEmailSended } = useAppState();

  const handleRestoreClick = () => {
    if (!EMAIL_REGEX.test(email)) {
      setEmailError(true);
    } else {
      setSendError(null);
      setLoading(true);
      sendActivationByEmail({ email })
        .then((resp) => {
          if ('error' in resp) {
            setSendError(resp.error);
            return;
          }
          if (resp.has_user) {
            setSendError('Your account has already been activated');
            return;
          }
          if (resp.success) {
            setActivationEmailSended(email);
          } else if (resp.success === false) {
            setSendError('Recovery email already sent. Please check your inbox');
          } else {
            setSendError('Something went wrong');
          }
        })
        .catch((err) => {
          console.error('sendActivationByEmail err', err);
          setSendError('Something went wrong');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const id = new URLSearchParams(window.location.search).get('id');

  if (id !== '' && id !== null) return <PasswordChange />;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          padding: '24px 24px 34px',
          maxWidth: '430px',
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          Access Recovery
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '15px',
            textAlign: 'center',
            marginTop: '16px',
          }}
        >
          Please enter the email address you provided when creating your account
        </Typography>

        <Box
          sx={{
            height: '1px',
            background: 'rgb(245,245,245)',
            width: '100%',
            margin: '24px 0',
          }}
        ></Box>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '16px',
            '& input': {
              width: '100%',
              height: '52px',
              background: 'rgb(245,245,245)',
              border: 'none',
              borderRadius: '12px',
              marginBottom: '16px',
              color: theme.palette.text.primary,
              fontFamily: 'Avenir Next',
              fontWeight: 500,
              fontSize: '17px',
              paddingLeft: '23px',
              '&:focus-visible': {
                outline: 'none',
              },
            },
            '& input::placeholder': {
              color: theme.palette.text.secondary,
              fontFamily: 'Avenir Next',
              fontWeight: 500,
              opacity: 0.5,
              fontSize: '17px',
            },
            '& p': {
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              fontSize: '13px',
              marginBottom: '4px',
            },
            '& .textWrapper': {
              display: 'flex',
              justifyContent: 'space-between',
            },
            '& .error': {
              color: 'rgb(253, 37, 69)',
            },
          }}
        >
          <Box className="textWrapper">
            <Typography>Email address</Typography>
            {emailError && <Typography className="error">Wrong email format</Typography>}
          </Box>
          <input
            placeholder="Enter your email address"
            type="email"
            style={{
              outline: emailError ? '1px solid rgb(253, 37, 69)' : undefined,
            }}
            value={email}
            onChange={(e) => {
              setEmailError(false);
              setEmail(e.target.value);
            }}
          ></input>
          {sendError !== null && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography className="error">{sendError}</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <button
            style={{
              cursor: 'pointer',
              background: '#256BFD',
              fontSize: '17px',
              color: '#fff',
              fontWeight: 600,
              fontFamily: 'Avenir Next',
              textTransform: 'none',
              height: '56px',
              width: '100%',
              borderRadius: '12px',
              border: 'none',
              WebkitTapHighlightColor: 'transparent',
            }}
            onClick={isLoading ? undefined : handleRestoreClick}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              ></CircularProgress>
            ) : (
              'Send email'
            )}
          </button>
        </Box>
      </Box>
    </Box>
  );
}

export default AccessRecovery;
